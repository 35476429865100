import { SUFFIX_FLAG } from "@/bluetooth/BluetoothData";

class NFCRepository {
  async readDeviceFlag(session,isSupportLevel) {
    if (isSupportLevel) {
      const flagDataView =  await session.readSingleBlock(0x1a)
      return flagDataView.getUint8(3)
    }else {
      const flagDataView =  await session.readSingleBlock(0x16)
      return flagDataView.getUint8(3)
    }
  }
  async readDeviceType(session) {
      const flagDataView = await session.readSingleBlock(0x1c)
      return flagDataView.getUint8(1)

  }

  async writeDeviceBattery(session, maxSecond) {
    let hexMaxSecond = parseInt(maxSecond).toString(16);
    let hexMaxSecondLength = hexMaxSecond.length;
    while (hexMaxSecondLength < 4) {
      hexMaxSecond = `0${hexMaxSecond}`;
      hexMaxSecondLength += 1;
    }

    // "03e4"
    let oilBatteryList = [];
    for (let i = 0; i < hexMaxSecond.length; i += 2) {
      oilBatteryList.unshift(hexMaxSecond.substr(i, i + 2));
    }
    const oilBatteryStr = oilBatteryList.join("").toUpperCase();
    const oilBatteryAsciiList = [];
    for (let i = 0; i < oilBatteryStr.length; i++) {
      oilBatteryAsciiList.push(oilBatteryStr.charCodeAt(i));
    }
    const sendMaxSecondDataView = new DataView(
      Buffer.from(new Uint8Array(oilBatteryAsciiList)).buffer
    );

    await session.writeSingleBlock(0x13, sendMaxSecondDataView);
  }

  async clearFlag(session, isSupportLevel) {
    if (!isSupportLevel) {
        //清空标志0xFB标志
        const block3 = await session.readSingleBlock(22);
        block3.setInt8(3, 0);
        await session.writeSingleBlock(22, block3);
      } else {
        //清空标志0xFB标志
        const block3 = await session.readSingleBlock(0x1a);
        block3.setInt8(3, 0);
        await session.writeSingleBlock(0x1a, block3);
      }
  }

  async setFlag(session,isSupportLevel) {
    if (!isSupportLevel) {
        //清空标志0xFB标志
        const block3 = await session.readSingleBlock(22);
        block3.setInt8(3, SUFFIX_FLAG);
        await session.writeSingleBlock(0x16, block3);
      } else {
        //清空标志0xFB标志
        const block3 = await session.readSingleBlock(0x1a);
        block3.setInt8(3, SUFFIX_FLAG);
        await session.writeSingleBlock(0x1a, block3);
      }

  }

  async writeDeviceLevel(session, currentLevel,groupNumber = 1) {

    const levelDataView = new DataView(Buffer.alloc(4).buffer);
    // if (false) {
      const target = ((groupNumber << 4) & 0xF0) | (currentLevel & 0x0F);
    // }
    // const target = currentLevel;
    levelDataView.setUint8(0, target);
    await session.writeSingleBlock(0x1b, levelDataView);
  }


  async writeDeviceCurve(session, writerSetting, currentLevel) {

    if (currentLevel > 0) {
      //生成写入数据
      const originMultiCurveDataView = await session.readMultipleBlock(0x14 + (currentLevel - 1) * 2, 0x14 + (currentLevel) * 2 + 1);

      // const firstBlockDataView = await session.readSingleBlock(0x14);
      // let firstData = firstBlockDataView.getUint8(0);
      // let secondData = firstBlockDataView.getUint8(0);
      // if (firstData & 0x01 > 0) {
      //   firstData &= 0xfe;
      //   firstBlockDataView.setUint8(0, firstData);
      //   await session.writeSingleBlock(20, firstBlockDataView);
      // }
      const startPosition = 2;
      writerSetting.diyVoltage.forEach((item, index) => {
        originMultiCurveDataView.setUint8(startPosition + index, Math.abs(item / 100));
        console.log(`当前电压等级: ${currentLevel} 写入的电压 ${index+1} => ${ Math.abs(item / 100)}`)
      });
      const packet = originMultiCurveDataView.byteLength / 4;
      for (let i = 0; i < packet; i++) {
        console.log(`写入的位置 ${ 0x14 + (currentLevel - 1) * 2 + i}`)
        await session.writeSingleBlock(
          0x14 + (currentLevel - 1) * 2 + i,
          new DataView(originMultiCurveDataView.buffer.slice(i * 4, i * 4 + 4))
        );
      }

      await this.setFlag(session,currentLevel > 0)

    } else {
      //生成写入数据
      const dataView = writerSetting.generateNfcCommand();
      const packet = dataView.byteLength / 4;
      for (let i = 0; i < packet; i++) {
        await session.writeSingleBlock(
          20 + i,
          new DataView(dataView.buffer.slice(i * 4, i * 4 + 4))
        );
      }
    }


  }

  async readDeviceVoltages() {
    //生成写入数据
    const originMultiCurveDataView = await session.readMultipleBlock(0x14, 0x1b);

    const originDiyVoltages = new DataView(originMultiCurveDataView.buffer.slice(2, 27));
    let originDiyVoltagesList = [];
    for (let i = 0; i < originDiyVoltages.byteLength; i++) {
      originDiyVoltagesList.push(originDiyVoltages.getUint8(i) * 100);
    }

    return originMultiCurveDataView;
  }
}

const nfcRepository = new NFCRepository();
export default nfcRepository;