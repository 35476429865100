
<template>
  <div class="content">
    <div class="brand-info row-margin" >
      <van-image fit="contain" :src="brand.brandPicture" alt="" class="img"/>
      <div class="name">{{brand.brandName}}</div>
    </div>
    <FlavorItem class="row-margin" :show-select="false" :flavor="flavor"/>
    <div class="scan-info" v-if="scanType === 0">
      <InfoRow v-for="(item,index) in tagInfo" :key="index" :label="item.label" :value="item.value" />
    </div>
    <div class="scan-info" v-if="scanType === 1">
      <InfoRow v-for="(item,index) in codeDeviceInfo" :key="index" :label="item.label" :value="item.value" />
    </div>


    <HistoryCurve v-if="scanType === 0"  :curve-color="curveColor"
        :device-level="nfcTagInfo.deviceLevel"
        :curve-line-color="curveLineColor"  :power="nfcTagInfo.power" :max-power="nfcTagInfo.maxPower" :resistance="nfcTagInfo.resistance"/>

    <div class="operate-content">
      <van-button :color="cancelButtonColor" class="cancel" @click="onCancel">Cancel</van-button>
      <van-button  :color="themeMainColor" class="confirm" @click="onConfirm">Confirm</van-button>
    </div>
  </div>
</template>

<script>
import FlavorItem from "@/components/FlavorItem.vue";
import ScanBoxQRCode from "@/components/ScanBoxQRCode.vue";
import InfoRow from "@/components/InfoRow.vue";
import HistoryCurve from "@/views/HistoryCurve.vue";
import { mapState } from "vuex";

export default {
  name: "FlavorConfirm",
  components: { HistoryCurve, InfoRow, ScanBoxQRCode, FlavorItem },
  props: {
    brand: {
      type: Object,
      default: () => {
        return { };
      }
    },
    flavor: {
      type: Object,
      default: () => {}
    },
    qrCodeInfo: {
      type: Object,
      default: () =>  {}
    },
    nfcTagInfo: {
      type: Object,
      default: () =>  {}
    },
    scanType: {
      type:Number,
      default: 1
    }
  },
  data() {
    return {
    }
  },
  methods: {
    onCancel() {
      this.$emit('onCancel')
    },
    onConfirm() {
      this.$emit('onConfirm',this.scanType === 1?this.qrCodeInfo:this.nfcTagInfo)
    }
  },
  computed: {
    ...mapState("theme",["themeMainColor","cancelButtonColor","curveColor","curveLineColor"]),
    codeDeviceInfo() {
      if (this.qrCodeInfo) {
        const info = []
        const {id,deviceTypeId,tagNumber,deviceTypeName} = this.qrCodeInfo
        info.push({label: 'Quantity',value:tagNumber})
        info.push({label: 'Model',value:deviceTypeName})
        return info
      }
      return []
    },
    tagInfo() {
      if (this.nfcTagInfo) {
        const info = []
        const {tagId,deviceModel} = this.nfcTagInfo
        info.push({label: 'ID',value:tagId})
        info.push({label: 'Model',value:deviceModel})
        return info
      }
      return []
    },
  }
};
</script>


<style scoped lang="less">
.content {
  width: 90vw;
  display: flex;
  flex-direction: column;
  color:#555555;
  .brand-info {
    margin-top:30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .img {
      width:50px;
      height:50px;
    }

    .name {
      margin-left:10px;
      font-size:18px;
    }
  }
  .row-margin {
    margin-left:15px;
    margin-right:15px;
  }
  .scan-info {
    padding:15px;
    display: flex;
    flex-direction: column;

  }
  .operate-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:15px;
    .cancel {
      border-radius: 5px;
      flex: 1;
      margin-right:10px;
      ::v-deep .van-button__text {
        color: #939598;
      }
    }
    .confirm {
      border-radius: 5px;
      flex: 1;
      color:white;
    }
  }

}
</style>