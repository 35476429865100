<template>
  <div class="container">

    <div class="header">
      <van-icon
          class="header-return"
          name="arrow-left"
          size="25"
          color="#bcbec0"
          @click="onClickHeaderReturn"
      />
      My Brand

      <van-icon
          class="header-record"
          :name="require('@/assets/icons/brand/icon_brand_record.png')"
          size="30"
          color="#bcbec0"
          @click="onClickHeaderRecord"
      />
    </div>

    <div v-if="status === 'finish'" class="content">
      <Stepper style="padding-top:20px" :data="stepOfData" :step="step"/>
      <BrandList v-if="step !== 3 " :value="selectedBrand" :brand-list="brandList" @input="onBrandChange"/>
      <FlavorList v-if="step === 2" :value="selectFlavor" :flavor-list="flavorList" @input="onFlavorChange"/>
      <FlavorInfo v-if="step===3" :brand="selectedBrandInfo" :flavor="selectedFlavorInfo"
          :box-number="scanNumber"
          :scan-type="scanType"
          :qr-code-info="qrCodeInfo"
          :connect-tag-info="nfcTagInfo"
          :show-info="showInfo"
          :disabled-nfc-button="disabledNfcButton"
          @onBackHome="onBackHome"
          @onSubmit="onSubmit"
          @onQRCodeClick="onQRCodeClick"
          @onConnectNfcClick="onConnectNfc"/>
    </div>

    <div v-if="status === 'empty'" class="no-data" @click="loadData" v-else>
      <div class="no-data">
        <img src="@/assets/icons/brand/icon_no_brand_data.png" style="width:150px;height:150px;"/>
        <span style="margin-top:20px;margin-bottom:60px;color: #999999">No data</span>
      </div>
    </div>

    <div class="footer"></div>
    <div class="create-button" v-if="step === 2">
      <van-button @click="onCreateToScan" style="margin-bottom:10px;border-radius: 10px;width:100%"
          :color="themeMainColor">Create
      </van-button>
    </div>

    <van-popup v-model="showScanQRCodeConfirm" round>
      <FlavorConfirm :brand="selectedBrandInfo" :flavor="selectedFlavorInfo"
          :qr-code-info="confirmQRCodeInfo"
          :nfc-tag-info="confirmNFCTagInfo"
          :scan-type="scanType"
          @onCancel="showScanQRCodeConfirm=false" @onConfirm="onFlavorInfoConfirm"/>
    </van-popup>

    <van-popup v-model="showCreatePopoup" round>
      <ScanTypeConfirm @onNFCClick="onNFCSelect" @onQRCodeClick="onQRCodeSelect"/>
    </van-popup>
    <!-- Android NFC Popup-->
    <van-popup
        class="nfc-android-popup"
        close-icon="close"
        round
        v-model="showAndroidNfcPopup"
        :close-on-click-overlay="true"
        close-icon-position="top-right"
    >
      <div class="popup-header">Tips</div>
      <div class="popup-content">
        <div class="content-title">
          Please move your device near the top your phone to activate your
          device.
        </div>
        <van-loading size="60px"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Stepper from "@/components/Stepper";
import BrandList from "@/components/BrandList";
import FlavorList from "@/components/FlavorList";
import FlavorInfo from "@/components/FlavorInfo";
import FlavorConfirm from "@/components/FlavorConfirm";
import produce from "@/api/produce";
import ScanTypeConfirm from "@/components/ScanTypeConfirm";
import { Toast } from "vant";
import { calcMaxPower, calcPower, calcVoltageFloorInt, convertTagId } from "@/util/utils";
import { mapMutations, mapState } from "vuex";
import nfcRepository from "@/nfc/NFCRepository";
import { SUFFIX_FLAG, WriterSetting } from "@/bluetooth/BluetoothData";

export default {
  name: "MyBrand",
  components: { ScanTypeConfirm, FlavorConfirm, FlavorInfo, FlavorList, Stepper, BrandList },
  data() {
    return {
      status: "loading",
      step: 1,
      stepOfData: [{
        icon: require("@/assets/icons/brand/icon_brand_step_1.png"),
        title: "Select Brand",
        step: 1,
        type: "icon"
      }, {
        icon: require("@/assets/icons/brand/icon_brand_step_2.png"),
        title: "Select Flavor",
        step: 2,
        type: "icon"
      }, {
        icon: require("@/assets/icons/brand/icon_brand_step_3.png"),
        title: "Scan",
        step: 3,
        type: "icon"
      }],
      selectedBrand: -1,
      selectFlavor: 0,
      showScanQRCodeConfirm: false,
      showCreatePopoup: false,
      showFlavorInfo: false,
      brandList: [],
      qrCodeInfo: null,
      nfcTagInfo: null,
      confirmQRCodeInfo: null,
      confirmNFCTagInfo: null,
      showInfo: false,
      alreadyScanQRCodeInfo: [],
      alreadyScanTagInfo: [],
      scanType: 0, //0是nfc 1是qrcode

      //

      disabledNfcButton: false,
      isInitErrorHandler: false,
      isInitBegingEvent: false,
      showAndroidNfcPopup: false,

      customGoHome: false
    };
  },
  methods: {
    ...mapMutations(["setKeepAlive"]),
    resetStatus() {
      this.qrCodeInfo = null;
      this.nfcTagInfo = null;
      this.selectedBrand = -1;
      this.selectFlavor = 0;
      this.alreadyScanQRCodeInfo = [];
      this.alreadyScanTagInfo = [];
      this.showInfo = false;
      this.showScanQRCodeConfirm = false;
      this.showFlavorInfo = false;
    },
    onClickHeaderReturn() {
      if (this.step > 1) {
        this.step -= 1;
        this.alreadyScanQRCodeInfo = [];
        this.alreadyScanTagInfo = [];
        if (this.step === 1) {
          this.resetStatus();
        }

        return;
      }
      this.$router.go(-1);
      this.resetStatus();
      this.setKeepAlive({ type: "remove", name: "MyBrand" });
    },
    onClickHeaderRecord() {
      this.$router.push({name:"BrandRecord",query:  {hash: this.$store.state.hash}});
    },
    onBrandChange(index) {
      this.selectedBrand = index;
      if (this.step === 1 && this.step <= 3) {
        this.step += 1;
        this.$router.push({name:"MyBrand",query:  {hash: this.$store.state.hash}});
      }
    },
    onFlavorChange(index) {
      this.selectFlavor = index;
    },
    onCreateToScan() {
      this.showInfo = false;

      this.showCreatePopoup = true;
      // this.step += 1;
    },
    async onQRCodeClick() {
      if (!navigator.qrcode) {
        this.$dialog.alert({ message: "Please use OMNI app,current environment is not supported" });
        return;
      }
      let result = await navigator.qrcode.scanQRCode();
      console.log(`二维码扫描结果 = ${result}`);
      let index = this.alreadyScanQRCodeInfo.findIndex(item => (item.code === result));
      if (index !== -1) {
        this.$dialog.alert({ message: "Repeated scans code" });
        return;
      }

      const resp = await produce.scanBrandQrcode({ code: result });
      const { data, code, message } = resp;
      if (code !== 200) {
        this.$dialog.alert({ message });
        return;
      }
      index = this.alreadyScanQRCodeInfo.findIndex(item => (item.deviceTypeId !== data.deviceTypeId));
      if (index !== -1) {
        this.$dialog.alert({ message: "Inconsistent device types" });
        return;
      }
      this.confirmQRCodeInfo = data;
      this.showScanQRCodeConfirm = true;
    },
    async onConnectNfc() {
      if (!navigator.nfcReader) {
        this.$dialog.alert({ message: "Please use OMNI app,current environment is not supported" });
        return;
      }
      const nfcReader = this.getNFCReader();

      if (!nfcReader) {
        return;
      }


      let tagId = null;
      try {
        const session = await nfcReader.beginReaderSession(2, { operate: 2 });
        tagId = convertTagId(session.id);
        let index = this.alreadyScanTagInfo.findIndex(item => (item.tagId === tagId));
        if (index !== -1) {
          nfcReader.endReaderSession(false, "Repeated tag");
          return;
        }
        await session.connect();
        //读取是否是多曲线的
        const curveLevelDataView = await session.readSingleBlock(0x1b);
        const levelFlag = curveLevelDataView.getUint8(0);
        const currentLevel = levelFlag & (0x0F); //取低四位是等级，高四位组号;
        const groupNumber = (levelFlag >> 4) & 0x0F; //组号
        const isSupportLevel = currentLevel > 0;
        console.log(`读取的等级为:${currentLevel}`);
        const flag = await nfcRepository.readDeviceFlag(session, isSupportLevel);
        console.log(`flag = ${flag}`);
        if (flag !== SUFFIX_FLAG) {
          throw ("No support device");
        }

        const startBlock = 0x14;
        let endBlock = 0x17;
        let checkLength = 12;
        let checkFlagPosition = 11;
        if (isSupportLevel) {
          endBlock = 0x1b;
          checkLength = 28;
          checkFlagPosition = 27;
        }

        //读取全部数据
        const dataView = await session.readMultipleBlock(startBlock, endBlock);
        console.log(`读取的长度: ${dataView.byteLength}`);
        if (dataView.byteLength === checkLength && dataView.getUint8(checkFlagPosition) !== 0xfb) {
          this.showAndroidNfcPopup = false;
          this.$toast({
            type: "fail",
            message: "Invalid data!",
            duration: 2000
          });
          nfcReader.endReaderSession(false, "Invalid data!");
          return;
        }

        const typeOfDevice = await nfcRepository.readDeviceType(session);

        let coilResistorValue = 1.2
        if (groupNumber > 0) {
          switch (groupNumber) {
            case 1:
               coilResistorValue = 1.0;
              break;
            case 2:
               coilResistorValue = 1.2;
              break;
            case 3:
               coilResistorValue = 1.4;
              break;
            case 4:
               coilResistorValue = 1.6;
              break;
            case 5:
               coilResistorValue = 1.8;
              break;
            case 6:
               coilResistorValue = 2.0;
              break;
            case 7:
               coilResistorValue = 2.1;
              break;
          }
        } else if ( typeOfDevice === 75 ||  typeOfDevice === 78) { //AD2301 AD2402
          coilResistorValue = 1.7
        }else if (typeOfDevice === 76) { // AD2601
           coilResistorValue = 1.0
        }else if ( typeOfDevice === 77 ||  typeOfDevice === 57) { //CP010203 Ad3701
           coilResistorValue = 1.3
        }else {
           coilResistorValue = 1.2
        }



        const writerSetting = new WriterSetting(dataView);
        if (isSupportLevel) {
          writerSetting.diyVoltage = writerSetting.diyVoltage.slice((currentLevel - 1) * 8, currentLevel * 8);
        }

        nfcReader.endReaderSession(true, "Read success");
        if (
            navigator.userAgent.indexOf("Android") > -1 ||
            navigator.userAgent.indexOf("Linux") > -1
        ) {
          this.$toast({
            type: "success",
            message: "Read success",
            duration: 2000
          });
        }
        this.showAndroidNfcPopup = false;
        this.disabledNfcButton = false;


        const resp = await produce.scanBrandQrcode({ tag: tagId });
        if (resp.code === 200) {

          const limitMaxVoltage = calcVoltageFloorInt(10, coilResistorValue);
          const maxPower = calcMaxPower(limitMaxVoltage, coilResistorValue);
          const powerList = writerSetting.diyVoltage.map(item => parseFloat(calcPower(item, coilResistorValue, maxPower).toFixed(2)));

          this.confirmNFCTagInfo = {
            tagId: tagId,
            deviceModel: resp.data.deviceTypeName,
            power: powerList,
            resistance: coilResistorValue,
            maxPower: 12,
            deviceLevel: currentLevel
          };
          this.showScanQRCodeConfirm = true;
        } else {
          this.$dialog.alert({ message: resp.message });

        }
        this.showAndroidNfcPopup = false;

      } catch (e) {
        console.log(e);
        this.showAndroidNfcPopup = false;
        if (
            navigator.userAgent.indexOf("Android") > -1 ||
            navigator.userAgent.indexOf("Linux") > -1
        ) {

          this.$dialog.alert({ message: e });
        }
        this.disabledNfcButton = false;
        nfcReader.endReaderSession(false, e);
      }
    },
    onBackHome() {
      this.customGoHome = true;
      this.setKeepAlive({ type: "remove", name: "MyBrand" });
      this.$router.go(-1);
    },
    async onSubmit() {
      const brandId = this.brandList[this.selectedBrand].id;
      const flavorId = this.selectedFlavorInfo.id;
      const operationType = 1;
      let codeOrTag = this.alreadyScanQRCodeInfo.map(item => item.code).join(",");
      const resp = await produce.uploadTagInfo({ brandId, flavorId, operationType, codeOrTag });
      if (resp.code === 1500) {
        this.$dialog.alert({ message: resp.message });
        return;
      } else if (resp.code === 200) {
        this.$dialog.alert({ message: resp.message }).then(() => {
          this.step = 1;
          this.resetStatus();
        });
      }
    },
    async onFlavorInfoConfirm(result) {
      if (this.scanType === 1) {
        if (this.qrCodeInfo === null) {
          this.qrCodeInfo = result;
        } else { //累加信息
          this.qrCodeInfo.tagNumber += result.tagNumber;
        }
        this.alreadyScanQRCodeInfo.push(Object.assign({}, result));
        this.showInfo = true;
        this.showScanQRCodeConfirm = false;
      } else {
        try {
          const brandId = this.brandList[this.selectedBrand].id;
          const flavorId = this.selectedFlavorInfo.id;
          const operationType = 0;
          const resp = await produce.uploadTagInfo({ brandId, flavorId, operationType, codeOrTag: result.tagId });
          if (resp.code === 200) {
            this.alreadyScanTagInfo.push(Object.assign({}, result));
            this.nfcTagInfo = result;
            this.showInfo = true;
            this.showScanQRCodeConfirm = false;
          } else {
            this.$dialog.alert({ message: resp.message });
          }
        } catch (e) {

        }
      }
    },
    onNFCSelect() {
      this.scanType = 0;
      this.step += 1;
      this.showCreatePopoup = false;
    },
    onQRCodeSelect() {
      this.scanType = 1;
      this.step += 1;
      this.showCreatePopoup = false;
    },


    getNFCReader() {
      if (this.disabledNfcButton) {
        return null;
      }
      const nfcReader = navigator.nfcReader;
      if (!nfcReader) {
        return null;
      }

      if (!this.isInitErrorHandler) {
        nfcReader.addEventListener(
            "receiveInvalidateWithError",
            this.handlerNFCError
        );
        this.isInitErrorHandler = true;
      }

      if (!this.isInitBegingEvent) {
        nfcReader.addEventListener(
            "receiveBegingActive",
            this.handleBeingActive
        );
        this.isInitBegingEvent = true;
      }

      if (
          navigator.userAgent.indexOf("Android") > -1 ||
          navigator.userAgent.indexOf("Linux") > -1
      ) {
        this.showAndroidNfcPopup = true;
      } else {
        this.disabledNfcButton = true;

        this.toastObj = Toast.loading({
          message: "Waiting for NFC to start...",
          forbidClick: true,
          duration: 0,
          loadingType: "spinner"
        });
      }
      return nfcReader;
    },

    handleBeingActive(event) {
      console.log("handleBeingActive ");
      this.toastObj.clear();
    },
    handlerNFCError(event) {
      console.log("出现错误 " + JSON.stringify(event.error));
      if (event.error.errorCode !== 200) {
        // navigator.nfcReader.endReaderSession(false, "");
      }
      console.log(`disabledNfcButton before = ${this.disabledNfcButton}`);
      this.disabledNfcButton = false;
      console.log(`disabledNfcButton after= ${this.disabledNfcButton}`);
    },

    async loadData() {
      this.status = "loading";
      const resp = await produce.brandList();
      const data = resp.data;
      if (data.length > 0) {
        this.brandList = data;
        this.status = "finish";
      } else {
        this.status = "empty";
      }
    }

  },
  destroyed() {
    console.log("destroyed");
    if (navigator.nfcReader) {
      navigator.nfcReader.removeEventListener(
          "receiveInvalidateWithError",
          this.handlerNFCError
      );
      navigator.nfcReader.removeEventListener(
          "receiveBegingActive",
          this.handleBeingActive
      );
    }

  },
  async created() {
  },
  beforeRouteLeave(to, from, next) {
    if ((to.name === "Connect" || to.name === "NewHome") && !this.customGoHome) {

      if (this.step === 1) {
        next();
        return;
      } else {
        this.onClickHeaderReturn();
        next(false);
        return false;
      }

    }
    next();
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    ...mapState("theme", ["themeMainColor"]),
    selectedBrandInfo() {
      if (this.selectedBrand === -1) {
        return null;
      }
      return this.brandList[this.selectedBrand];
    },
    flavorList() {
      if (this.selectedBrandInfo) {
        return this.selectedBrandInfo.flavorList;
      }
      return null;
    },
    selectedFlavorInfo() {
      if (this.flavorList) {
        return this.flavorList[this.selectFlavor];
      }
      return null;
    },
    scanNumber() {
      if (this.scanType === 0) {
        return this.alreadyScanTagInfo.length;
      } else {
        return this.alreadyScanQRCodeInfo.length;
      }
    }
  }
};
</script>

<style scoped lang="less">
.container {
  height: 100%;
  background: #F1F2F2;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content {
  background: white;
  height: 100%;
  overflow-y: auto;
}

.header {
  width: 100%;
  height: 60px;
  min-height: 60px;
  background: #f1f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #545554;

  .header-return {
    position: absolute;
    left: 10px;
  }

  .header-record {
    position: absolute;
    right: 10px;

  }

}

.create-button {
  background: white;
  position: fixed;
  bottom: 0px;
  left: 10px;
  right: 10px;
}

.no-data {
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: white;
  color: #939598;

}


.nfc-android-popup {
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;

  align-items: center;

  .popup-header {
    display: flex;
    justify-content: center;
    margin: 20px 0 40px 0;
    font-size: 21px;
    font-weight: bold;
    color: #6d6e71;
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: normal;
    color: #231f20;
    padding: 0 17px;
  }
}
</style>