
<template>
  <div class="content">
    <div class="title">Scan type</div>
    <div class="items">
      <div class="item"  @click="onNFCClick">
        <img src="@/assets/icons/brand/icon_brand_scan_nfc.png" class="icon"/>
        <van-button :color="themeMainColor" class="confirm">NFC</van-button>
      </div>

      <div style="height: 60px;width:1px; background: #F1F2F2;margin: 0 10px 84px 10px;"></div>
      <div class="item" @click="onQRCodeClick">
        <img src="@/assets/icons/brand/icon_qrcode.png"  class="icon" />
        <van-button :color="cancelButtonColor"  class="cancel" >QR CODE</van-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ScanTypeConfirm",
  methods: {
    onNFCClick() {
      this.$emit("onNFCClick")
    },
    onQRCodeClick() {
      this.$emit("onQRCodeClick")
    }
  },
  computed: {
    ...mapState("theme", ["cancelButtonColor","themeMainColor"])
  }
};
</script>


<style scoped lang="less">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width:70vw;
  .title {
    color:#6D6E71;
    font-size:21px;
  }

  .items {
    margin-top:40px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .icon {
      width: 70px;
      height:70px;
    }
  }
}


.cancel {
  width:100%;
  padding:15px;
  margin-top:30px;
  border-radius: 5px;
  flex: 1;
  ::v-deep .van-button__text {
    color: #939598;
  }
}
.confirm {
  width:100%;
  padding:15px;
  margin-top:30px;
  border-radius: 5px;
  flex: 1;
  color:white;
}
</style>