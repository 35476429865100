<script>
import FlavorItem from "@/components/FlavorItem.vue";

export default {
  name: "FlavorList",
  components: { FlavorItem },
  props: {
    value: {
      type: Number,
      default: 0
    },
    flavorList: {
      type:Array,
      default: ()=> []
    }
  },
  methods: {
    onFlavorChange(index) {
      this.$emit("input",index)
    }
  }
};
</script>

<template>
  <div class="flavor-content">
    <div class="title">Flavor</div>
    <van-list class="flavor-list">
      <FlavorItem v-for="(item,index) in flavorList"  :key="index" :flavor="item" :select="index === value" @click.native="onFlavorChange(index)"/>
    </van-list>
  </div>
</template>

<style scoped lang="less">
.flavor-content {
  padding-bottom:80px;
  .title {
    margin:10px 0 0 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .flavor-list {
    margin:15px 15px 0 15px;
  }
}
</style>