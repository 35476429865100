<script>
export default {
  name: "BrandList",
  props: {
    brandList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    onBrandClick(index) {
      this.$emit("input", index);
    }
  }
};
</script>

<template>
  <div class="brand-list-content">
    <p class="title">Brand</p>
    <div class="brand-list-scroll">
      <div class="brand" :class="index === value ? `brand-active`: ``"
          v-for="(item, index) in brandList" :key="index" @click="onBrandClick(index)">
        <van-image class="brand-icon" fit="contain" :src="item.brandPicture"/>
        <span class="brand-title">{{ item.brandName }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.brand-list-content {
  font-size: 18px;
  background: white;
  color: #555555;
  padding-bottom:1px;

  .title {
    margin: 20px 0 0 15px;
    font-weight: bold;
  }

  .brand-list-scroll {
    margin: 10px 0 15px 15px;
    display: flex;
    flex-direction: row;
    background: white;
    overflow-x: auto;

    .brand {
      padding: 15px;
      border-radius: 10px;
      margin-right: 20px;
      width:calc(33% - 10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #F1F2F2;
      box-shadow: 1px 1px 1px -1px rgba(0, 0, 0, 0.15);

      .brand-icon {
        width: auto;
        height: 50px;
      }

      .brand-title {
        margin-top: 20px;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        width:60px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &-active {
        border: 1px solid var(--themeMainColor);
      }
    }
  }
}
</style>