<script>
import { mapState } from "vuex";

export default {
  name: "Stepper",
  props: {
    step: {
      type: Number,
      default: 1
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState("theme",["themeMainColor"]),
    style() {
      return {
          activeBackgroundStyle: {
            background: this.themeMainColor,
            borderRadius: "50%",
            width: "36px",
            height: "36px",
          }
      }
    },
    stepperData() {
      const data = [];
      const length = this.data.length;
      this.data.forEach((item, index) => {
        data.push(item);
        if (index !== length - 1) {
          data.push({ type: "divider" ,step: item.step });
        }
      });
      return data;
    }
  }
};
</script>

<template>
  <div class="stepper-container">
    <div class="content-step">
      <div class="step-items">


        <template v-for="(item,index) in stepperData">
          <div v-if="item.type === 'icon'" style="display: flex;flex-direction: column;align-items: center;width:100px;">
            <div class="img-wrap"
                :style="(item.step <= step)? style.activeBackgroundStyle:style.normalBackgroundStyle">
              <img :src="item.icon"/>

            </div>
            <div class="wrap-title" >
              <div style="text-align: center">{{item.title}}</div>
            </div>

          </div>

          <div v-else-if="item.type==='divider'" class="step-split" :class="item.step < step ? `step-split-active`:``">

          </div>

        </template>

      </div>


    </div>
  </div>
</template>

<style scoped lang="less">
.stepper-container {

  text-align: center;
  .content-step {
    display: flex;
    justify-content: center;
    margin-right: 5px;
    width: 100%;
    align-items: center;
    flex-direction: column;

    .step-split {
      flex: 1;
      height: 2px;
      margin: -22px -20px 0 -20px;
      background-color: #A7A9AC;
      &-active {
        background-color: var(--themeMainColor);
      }
    }

    .step-items {
      width: 90%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    .step-item {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 50%;


    }

    .img-wrap {
      background: #A7A9AC;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .wrap-title {
    color: #555555;
    width:90%;
    margin-top:8px;
    font-size:14px;
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-around;
  }
}
</style>